import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';

const RegistroBarbeiroForm = ({ textoBotao, barbeiroId }) => {
  const [nome, setNome] = useState('');
  const [sobre, setSobre] = useState('');
  const [email, setEmail] = useState('');
  const redirecionar = useNavigate();

  useEffect(() => {
    if (barbeiroId) {
      const fetchBarbeiroData = async () => {
        try {
          const response = await api.post(`/barbeiro/id`, {
            barbeiroId
          });
          const barbeiroData = response.data.barbeiro;
          const emailBarbeiro = response.data.emailUsuario;
          setNome(barbeiroData.nome || '');
          setSobre(barbeiroData.sobre || '');
          setEmail(emailBarbeiro || '');
        } catch (error) {
          console.error('Erro ao carregar dados do usuário:', error);
        }
      };
      fetchBarbeiroData();
    }
  }, [barbeiroId]);

  const registrar = async (e) => {
    e.preventDefault();
    try {
      if (barbeiroId) {
        await api.put('/atualizarBarbeiro', {
          barbeiroId,
          nome: nome,
          sobre: sobre,
          email: email
        })
      } else {
        await api.post('/cadastroBarbeiro', {
          nome: nome,
          sobre: sobre,
          email: email
        })
      }
      redirecionar('/minha-conta');
    } catch (error) {
      console.error('Erro ao registrar barbeiro:', error);
    }
  };

  return (
    <form onSubmit={registrar}>
      <div>
        <label>Nome</label>
        <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
      </div>
      <div>
        <label>Sobre</label>
        <input type="text" value={sobre} onChange={(e) => setSobre(e.target.value)} />
      </div>
      <div>
        <label>email</label>
        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <button type="submit">{textoBotao}</button>
    </form>
  )
}

export default RegistroBarbeiroForm;