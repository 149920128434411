import '../../assets/styles/ModalConfirmacao.css';
import FormRegistrarCliente from "../forms/CadastroClienteForm";

const ModalCadastroCliente = ({ isOpen, titulo, textoBotao, clienteId, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className="modal-header">
                    <h2>{titulo}</h2>
                    <button style={{ backgroundColor: '#ffffff00', border: 'none', fontSize: '30px' }} onClick={onCancel}>x</button>
                </div>
                <div className="modal-body">
                    <FormRegistrarCliente 
                    textoBotao={textoBotao}
                    clienteId={clienteId}
                    />
                </div>
            </div>
        </div>

    );
};

export default ModalCadastroCliente;
