import React, { useState } from 'react';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/login.css'
import logoCasaBrasil from '../assets/images/logocasabrasil.png';
import GoogleLoginButton from '../components/GoogleLoginButton';
import { encriptar } from '../utils/criptografar';

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const redirecionar = useNavigate();

    const login = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/usuarios/login', {
                email,
                senha: password,
            });
            if (response.status === 200 && response.data.token) {
                localStorage.setItem('token', response.data.token);
                const dadosEncriptados = encriptar(response.data.userData);
                localStorage.setItem('usuarioAtual', dadosEncriptados);
                redirecionar('/minha-conta');
            } else {
                setError(response.data.message || 'Erro ao fazer login.');
            }
        } catch (error) {
            setError('Nome de usuário ou senha inválida');
        }
    };

    return (
        <div className='divLogin'>
            <div className='divFormLogin'>
                <div className='efeitoLuz'></div>
                <img src={logoCasaBrasil} alt='' />
                <form onSubmit={login}>
                    <div className='camposForm'>
                        <label>usuário</label>
                        <input style={{borderColor: error ? 'red' : '#4E4E55'}} placeholder='fulanodasilva@gmail.com' type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className='camposForm'>
                        <label>senha</label>
                        <input style={{borderColor: error ? 'red' : '#4E4E55'}} placeholder='senha' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </div>
                    <button type="submit">Login</button>
                </form>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap',alignItems:'center', width:'100%', gap:'20px' }}>
                    <p style={{ width: '30%', borderBottom: '0.5px solid #ffffff30' }}></p>
                    <p style={{ color: '#fff', width:'40%', textAlign:'center', fontWeight:'300' }}>ou acesse com</p>
                    <p style={{ width: '30%', borderBottom: '0.5px solid #ffffff30' }}></p>
                </div>
                <GoogleLoginButton />
                <p style={{ color: '#fff', width:'100%', textAlign:'center', fontWeight:'300'}}>não possui uma conta? <a href='/criar-conta/' style={{color:'#fff'}}>Crie uma agora</a></p>
            </div>
        </div>
    );
};

export default LoginForm;
