import api from "../api"


export const getUsuarios = async (qtdeExibicao,pagina) => {
    try {
        const response = await api.post('/usuariosAtivos', {
            qtdeExibicao, 
            pagina
        });
        return {
            usuarios: response.data.usuarios,
            totalPaginas: response.data.totalPaginas
        };
    }
    catch (error) {
        console.error('Erro ao buscar os usuários:', error);
        throw error;
    }
};
export const desativarUsuario = async (idUsuario, setUsuarios, setError) => {
    try {
        await api.put(`/desativarUsuario/`, {
            idUsuario
        });
        const usuarioData = await getUsuarios();
        setUsuarios(usuarioData);
    } catch (error) {
        setError('Erro ao desativar usuário:', error);
    }
};
export const buscarUsuario = async (busca) => {
    try {
        const response = await api.post('/usuariosBuscados', {
            busca
        });
        return response.data;
    } catch (error) {
        console.log('erro');
    }
};
export const ordenarUsuarios = (usuarios, criterio, ordem = 'asc') => {
    return [...usuarios].sort((a, b) => {
        const valorA = typeof a[criterio] === 'string' ? a[criterio].toLowerCase() : a[criterio];
        const valorB = typeof b[criterio] === 'string' ? b[criterio].toLowerCase() : b[criterio];
        if (valorA < valorB) return ordem === 'asc' ? -1 : 1;
        if (valorA > valorB) return ordem === 'asc' ? 1 : -1;
        return 0;
    });
};