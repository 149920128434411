import { useEffect, useState } from 'react';
import Calendar from '../components/Calendario';
import SideMenu from '../components/SideMenu';
import '../assets/styles/paginaAgendamentos.css';
import { getBarbeirosAtivos } from '../services/controller/barbeiroController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { getAgendamentos, obterTempoDoServico } from '../services/controller/agendamentoController';
import ModalCadastroAgendamento from "../components/modais/ModalCadastroAgendamento";
import { getClientes } from '../services/controller/clienteController';
import { getServicos } from '../services/controller/servicoController';


const Agendamentos = () => {
  const [barbeiros, setBarbeiros] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [agendamentos, setAgendamentos] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [error, setError] = useState(null);
  const [barbeirosSelecionados, setBarbeirosSelecionados] = useState([]);
  const [diaSelecionado, setDiaSelecionado] = useState(new Date());
  const [largurasAgendamentos, setLargurasAgendamentos] = useState({});
  const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
  const [modalEdicaoAberto, setModalEdicaoAberto] = useState(false);
  const [agendamentoSelecionadoId, setAgendamentoSelecionadoId] = useState(null);

  const coresFixas = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#A133FF'];

  useEffect(() => {
    const fetchBarbeirosEAgendamentos = async () => {
      try {
        const barbeiroData = await getBarbeirosAtivos();
        setBarbeiros(barbeiroData);
        setBarbeirosSelecionados(barbeiroData.map(barbeiro => barbeiro.id));
        const agendamentosData = await getAgendamentos();
        setAgendamentos(agendamentosData);
        const clientesData = await getClientes();
        setClientes(clientesData);
        const servicosData = await getServicos(); 
        setServicos(servicosData);

        const larguras = {};
        for (const agendamento of agendamentosData) {
          const tempoAgendamento = await obterTempoDoServico(agendamento.id);
          const minutosGrid = 16 * 60;
          const porcentagem = (tempoAgendamento / minutosGrid) * 100;
          larguras[agendamento.id] = porcentagem;
        }
        setLargurasAgendamentos(larguras);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchBarbeirosEAgendamentos();
  }, []);

  const fecharModalCadastro = () => {
    setModalCadastroAberto(false);
    setAgendamentoSelecionadoId(null);
  };
  const fecharModalEdicao = () => {
    setModalEdicaoAberto(false);
    setAgendamentoSelecionadoId(null);
  };

  const barbeirosSelecao = (barbeiroId) => {
    setBarbeirosSelecionados((prevSelecionados) =>
      prevSelecionados.includes(barbeiroId)
        ? prevSelecionados.filter((id) => id !== barbeiroId)
        : [...prevSelecionados, barbeiroId]
    );
  };

  const getCorBarbeiro = (barbeiroId) => {
    const index = barbeiros.findIndex(barbeiro => barbeiro.id === barbeiroId);
    return coresFixas[index % coresFixas.length];
  };

  if (error) {
    return <p>Erro: {error}</p>;
  }

  const formatarData = (data) => {
    return new Date(data).toISOString().split('T')[0];
  };

  const calcularLeftPorHora = (hora) => {
    const minutosGrid = 16 * 60;
    const [horas, minutos] = hora.split(':').map(Number);
    const horasReferentesAoInicioTL = horas - 6;
    const minutosPassados = (horasReferentesAoInicioTL * 60) + minutos;
    const porcentagem = (minutosPassados / minutosGrid) * 100;
    return porcentagem;
  };

  const agendamentosFiltrados = agendamentos
    .filter(agendamento =>
      barbeirosSelecionados.includes(agendamento.barbeiro_id) &&
      formatarData(agendamento.dataAgendada) === formatarData(diaSelecionado)
    );

  return (
    <>
      <ModalCadastroAgendamento
        isOpen={modalCadastroAberto}
        titulo={'Novo Agendamento'}
        textoBotao={'Adicionar Agendamento'}
        clientes={clientes}
        barbeiros={barbeiros}
        servicos={servicos}
        onCancel={fecharModalCadastro}
      />
      <ModalCadastroAgendamento
        isOpen={modalEdicaoAberto}
        titulo={'Editar Agendamento'}
        textoBotao={'Salvar edição'}
        agendamentoId={agendamentoSelecionadoId}
        onCancel={fecharModalEdicao}
      />
      <SideMenu />
      <div className="divPrincipalMinhaConta">
        <div className="superiorMinhaConta">
          <h1>Agendamentos</h1>
          <button onClick={() => setModalCadastroAberto(true)}>Novo Agendamento</button>
        </div>
        <div className="gridAgendamentos">
          <div className='filtrosAgendamentos'>
            <Calendar onDiaSelecionadoChange={setDiaSelecionado} />
            <div className='linhaDivisao'></div>
            {barbeiros.map((barbeiro, index) => (
              <div
                className="barbeirosFiltro"
                key={barbeiro.id}
                onClick={() => barbeirosSelecao(barbeiro.id)}
                style={{
                  backgroundColor: barbeirosSelecionados.includes(barbeiro.id)
                    ? getCorBarbeiro(barbeiro.id)
                    : '#FFF'
                }}
              >
                <div className='fotoENome'>
                  <FontAwesomeIcon icon={faUser} />
                  <p style={{ width: '30vw' }}>{barbeiro.nome}</p>
                </div>
                <div className='linhaDivisaoHorizontal'></div>
                <div className='infosAgendamentosBarbeiros'>
                  <div className='statusAgendamentosBarbeiros'>
                    <p>Confirmados</p>
                    <p>20</p>
                  </div>
                  <div className='statusAgendamentosBarbeiros'>
                    <p>Finalizados</p>
                    <p>5</p>
                  </div>
                  <div className='statusAgendamentosBarbeiros'>
                    <p>Pendentes</p>
                    <p>5</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='visualizacaoHorarios'>
            <div className='visualizacaoDia'>
              {agendamentosFiltrados.map((agendamento) => {
                const dataObj = new Date(agendamento.dataAgendada);
                const hora = dataObj.toTimeString().split(' ')[0].substring(0, 5);
                const porcentagemWidth = largurasAgendamentos[agendamento.id] || 0;

                return (
                  <div key={agendamento.id} onClick={() => {
                    setModalEdicaoAberto(true)
                    setAgendamentoSelecionadoId(agendamento.id)
                  }} style={{ display: 'flex', flexDirection: 'column', position: 'relative', left: `${calcularLeftPorHora(hora)}%`, width: `${porcentagemWidth}%` }}>
                    <div
                      className="agendamentoItem"
                      style={{
                        backgroundColor: getCorBarbeiro(agendamento.barbeiro_id),
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '40px',
                        padding: '10px',
                        borderRadius: '8px',
                        color: '#fff',
                      }}
                    >
                      <p>{`${hora}`}</p>
                    </div>
                  </div>
                );
              })}
              <div className='linhaDoTempo'>
                <p>6:00</p>
                <p>8:00</p>
                <p>10:00</p>
                <p>12:00</p>
                <p>14:00</p>
                <p>16:00</p>
                <p>18:00</p>
                <p>20:00</p>
              </div>
            </div>
            <div className='visualizacaoSemana'>
              <p>Dia Selecionado: {formatarData(diaSelecionado)}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Agendamentos;
