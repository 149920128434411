import api from "../api"


export const getClientes = async() =>{
    try{
        const response = await api.get('/clientesAtivos');
        return response.data;
    }
    catch(error){
        console.error('Erro ao buscar os Clientes:', error);
        throw error;
    }
};
export const desativarCliente = async (idCliente,setClientes,setError) => {
    try {
        await api.put(`/desativarCliente/`, {
            idCliente
        });
        const clienteData = await getClientes();
        setClientes(clienteData);
    } catch (error) {
        setError('Erro ao desativar Cliente:', error);
    }
};
export const buscarCliente = async (busca) => {
    try {
        const response = await api.post('/clientesBuscados', {
            busca
        });
        return response.data;
    } catch (error) {
        console.log('erro');
    }
};
export const ordenarClientes = (clientes, criterio, ordem = 'asc') => {
    return [...clientes].sort((a, b) => {
        const valorA = typeof a[criterio] === 'string' ? a[criterio].toLowerCase() : a[criterio];
        const valorB = typeof b[criterio] === 'string' ? b[criterio].toLowerCase() : b[criterio];
        if (valorA < valorB) return ordem === 'asc' ? -1 : 1;
        if (valorA > valorB) return ordem === 'asc' ? 1 : -1;
        return 0;
    });
};