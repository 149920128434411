import { jwtDecode } from "jwt-decode";
import { desencriptar } from "./criptografar";

const isTokenValid = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp - currentTime > 0)
            return true
        else
            return false
    } catch (error) {
        return false;
    }
};

export const funcaoUsuario = () => {
    const dadosLocais = localStorage.getItem('usuarioAtual');
    if (dadosLocais) {
        const dadosDesencriptados = desencriptar(dadosLocais);
        const funcao = dadosDesencriptados.funcao;
        return funcao;
    } return null
}
export const nomeUsuario = () => {
    const dadosLocais = localStorage.getItem('usuarioAtual');
    if (dadosLocais) {
        const dadosDesencriptados = desencriptar(dadosLocais);
        const nome = dadosDesencriptados.nome;
        return nome;
    } return null
}

export const isLoggedIn = () => {
    const token = localStorage.getItem('token');
    return !!token && isTokenValid(token);
};

export const isAdmin = () => {
    if (funcaoUsuario() === 'Administrador') {
        return true;
    }
    return false;
};

export const isClient = () => {
    if (funcaoUsuario() === 'Cliente') {
        return true;
    }
    return false;
};
export const isBarber = () => {
    if (funcaoUsuario() === 'Barbeiro') {
        return true;
    }
    return false;
};

