import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';

const RegistroUsuarioForm = ({ textoBotao, idUsuario }) => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [funcao, setFuncao] = useState('');
  const redirecionar = useNavigate();

  useEffect(() => {
    if (idUsuario) {
      const fetchUserData = async () => {
        try {
          const response = await api.post(`/usuario/id`,{
            idUsuario
          });
          const userData = response.data;
          setEmail(userData.email || '');
          setFuncao(userData.funcao || 'Cliente');
        } catch (error) {
          console.error('Erro ao carregar dados do usuário:', error);
        }
      };
      fetchUserData();
    }
  }, [idUsuario]);

  const registrar = async (e) => {
    e.preventDefault();
    try {
      if (idUsuario) {
        await api.put(`/usuarios/atualizar/`, {
          idUsuario,
          senha: password,
          email,
          funcao,
        });
      } else {
        await api.post('/usuarios/criar/', {
          senha: password,
          email,
          funcao,
        });
      }
      redirecionar('/minha-conta');
    } catch (error) {
      console.error('Erro ao registrar/atualizar usuário:', error);
    }
  };

  return (
    <form onSubmit={registrar}>
      <div>
        <label>Password</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div>
        <label>Email</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div>
        <label>Função</label>
        <select value={funcao} onChange={(e) => setFuncao(e.target.value)}>
          <option value="Administrador">Administrador</option>
          <option value="Barbeiro">Barbeiro</option>
          <option value="Cliente">Cliente</option>
        </select>
      </div>
      <button type="submit">{textoBotao}</button>
    </form>
  );
};

export default RegistroUsuarioForm;
