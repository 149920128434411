const HomePage = () => {
    return (
        <div>
            <p color="#000">Essa é a home page</p>
        </div>
    );
}
export default HomePage;


