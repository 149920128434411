import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/criarConta.css';

const CriarConta = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [error, setError] = useState('');
  const [telefone, setTelefone] = useState('');
  const [proximo, setProximo] = useState(true);
  const redirecionar = useNavigate();

  // const [cep, setCep] = useState('');
  // const [cidade, setCidade] = useState('');
  // const [bairro, setBairro] = useState('');
  // const [errorCep, setErrorCep] = useState('');
  useEffect(() => {
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        setError('As senhas não coincidem.');
        setProximo(true);
      } else {
        if (email) {
          setError('');
          setProximo(false);
        }
      }
    } else {
      setProximo(true);
    }
  }, [email, password, confirmPassword]);

  const registrar = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/novoCliente', {
        nome: nome,
        email: email,
        senha: password,
        telefone: telefone,
      });
      console.log(response);
      if (response.status === 201) {
        redirecionar('/login');
      }
    } catch (error) {
      console.error('Erro ao registrar o cliente:', error);
    }
  };

  // const checarCep = () => {
  //   const cepFormatado = cep.replace(/\D/g, '');
  //   if (cepFormatado.length === 8) {
  //     fetch(`https://viacep.com.br/ws/${cepFormatado}/json/`)
  //       .then(res => res.json())
  //       .then(data => {
  //         if (!data.erro) {
  //           setErrorCep('');
  //           setCidade(data.localidade);
  //           setBairro(data.bairro);
  //         } else {
  //           setErrorCep('CEP não encontrado.');
  //           setCidade('');
  //           setBairro('');
  //         }
  //       })
  //       .catch(() => {
  //         setErrorCep('Erro ao buscar o CEP.');
  //         setCidade('');
  //         setBairro('');
  //       });
  //   } else {
  //     setErrorCep('CEP inválido.');
  //   }
  // };

  return (
    <div className="formCriarConta">
      <div className="modal">
        <div className="modal-header" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
          <h2>Criar Conta</h2>
        </div>
        <div className="modal-body">
          <form onSubmit={registrar}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <div>
                <label>Nome</label>
                <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
              </div>
              <div>
                <label>Telefone</label>
                <input type="text" value={telefone} onChange={(e) => setTelefone(e.target.value)} />
              </div>
              <div>
                <label>Email</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div>
                <label>Senha</label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div>
                <label>Confirme sua senha</label>
                <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </div>
              <button type="submit" disabled={proximo}>Criar Conta</button>
            </div>

            {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <div>
                <label>Cep</label>
                <input type="text" value={cep} onChange={(e) => setCep(e.target.value)} onBlur={checarCep} />
                {errorCep && <p style={{ color: 'red' }}>{errorCep}</p>}
              </div>
              <div>
                <label>Cidade</label>
                <input type="text" value={cidade} onChange={(e) => setCidade(e.target.value)} readOnly />
              </div>
              <div>
                <label>Bairro</label>
                <input type="text" value={bairro} onChange={(e) => setBairro(e.target.value)} readOnly />
              </div>
            </div>
             */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CriarConta;
