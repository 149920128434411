import api from "../api"

export const getBarbeirosAtivos = async () => {
    try {
        const response = await api.get('/barbeirosAtivos');
        return response.data;
    }
    catch (error) {
        console.error('Erro ao buscar os barbeiros:', error);
        throw error;
    }
};
export const buscarBarbeiro = async (busca) => {
    try {
        const response = await api.post('/barbeirosBuscados', {
            busca
        });
        return response.data
    } catch (error) {
        console.log('erro');
    }
};

export const ordenarBarbeiros = (barbeiros, criterio, ordem = 'asc') => {
    return [...barbeiros].sort((a, b) => {
        if (a[criterio] < b[criterio]) return ordem === 'asc' ? -1 : 1;
        if (a[criterio] > b[criterio]) return ordem === 'asc' ? 1 : -1;
        return 0;
    });
};

export const desativarBarbeiro = async (idBarbeiro, setBarbeiros, setError) => {
    try {
        await api.put(`/desativarBarbeiro/`, {
            idBarbeiro
        });
        const barbeiroData = await getBarbeirosAtivos();
        setBarbeiros(barbeiroData);
    } catch (error) {
        setError('Erro ao desativar barbeiro: ' + error.message);
    }
};

