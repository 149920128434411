import { useEffect, useState } from "react";
import { getUsuarios, ordenarUsuarios, desativarUsuario, buscarUsuario } from "../services/controller/usuarioController";
import SideMenu from "../components/SideMenu";
import ModalCadastro from "../components/modais/ModalCadastroUsuario";
import ModalConfirmacaoAcao from '../components/modais/ModalConfirmacaoAcao';
import ModalSucesso from '../components/modais/ModalSucesso';
import '../assets/styles/padraoMinhaConta.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDesc, faTrash, faEdit, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";

const PageUsuario = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [error, setError] = useState(null);
    const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
    const [modalEdicaoAberto, setModalEdicaoAberto] = useState(false);
    const [usuarioSelecionadoId, setUsuarioSelecionadoId] = useState(null);
    const [modalConfirmacaoAberto, setModalConfirmacaoAberto] = useState(false);
    const [usuarioParaExcluir, setUsuarioParaExcluir] = useState(null);
    const [emailUsuarioParaExcluir, setEmailUsuarioParaExcluir] = useState('');
    const [modalSucessoExclusaoAberto, setModalSucessoExclusaoAberto] = useState(false);
    const [qtdeExibicao, setQtdeExibicao] = useState(10);
    const [pagina, setPagina] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);
    const redirecionar = useNavigate();

    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const userData = await getUsuarios(qtdeExibicao, pagina);
                setUsuarios(userData.usuarios);
                setTotalPaginas(userData.totalPaginas);
            } catch (error) {
                setError(error.message);
            }
        };
        fetchUsuarios();
    }, [pagina, qtdeExibicao]);

    const filtroBusca = async (e) => {
        const busca = e.target.value;
        const usuariosRetorno = await buscarUsuario(busca);
        setUsuarios(usuariosRetorno);
    };

    const ordenacao = (ordem, criterio) => {
        const usuariosOrdenados = ordenarUsuarios(usuarios, criterio, ordem);
        setUsuarios(usuariosOrdenados);
    };

    const fecharModalCadastro = () => {
        setModalCadastroAberto(false);
        setUsuarioSelecionadoId(null);
    };
    const fecharModalEdicao = () => {
        setModalEdicaoAberto(false);
        setUsuarioSelecionadoId(null);
    };
    const fecharModalConfirmacao = () => {
        setModalConfirmacaoAberto(false);
    };
    const fecharModalSucesso = () => {
        setModalSucessoExclusaoAberto(false);
        redirecionar('/gerenciar/usuarios');
    }

    const confirmarExclusao = async () => {
        if (usuarioParaExcluir) {
            try {
                await desativarUsuario(usuarioParaExcluir, setUsuarios, setError);
                fecharModalConfirmacao();
                setModalSucessoExclusaoAberto(true);
            } catch (error) {
                setError(error.message);
                fecharModalConfirmacao();
            }
        }
    };

    const mudarPagina = (novaPagina) => {
        if (novaPagina > 0 && novaPagina <= totalPaginas) {
            setPagina(novaPagina);
        }
    };

    if (error) {
        return <p>Erro: {error}</p>;
    }

    return (
        <>
            <SideMenu />
            <div className="divPrincipalMinhaConta">
                <ModalSucesso
                    isOpen={modalSucessoExclusaoAberto}
                    titulo={`Sucesso!`}
                    mensagem={`Usuario ${emailUsuarioParaExcluir} excluido com sucesso`}
                    acaoConfirmar={fecharModalSucesso}
                />
                <ModalConfirmacaoAcao
                    isOpen={modalConfirmacaoAberto}
                    titulo={`Excluir ${emailUsuarioParaExcluir}?`}
                    mensagem="Você tem certeza que deseja excluir este usuário?"
                    acaoCancelar={fecharModalConfirmacao}
                    acaoConfirmar={confirmarExclusao}
                />
                <ModalCadastro
                    isOpen={modalCadastroAberto}
                    titulo="Novo Usuário"
                    textoBotao={'Adicionar Usuário'}
                    onCancel={fecharModalCadastro} />
                <ModalCadastro
                    isOpen={modalEdicaoAberto}
                    titulo="Alterar Usuário"
                    textoBotao={'Salvar edição'}
                    usuarioId={usuarioSelecionadoId}
                    onCancel={fecharModalEdicao} />
                <div className="superiorMinhaConta">
                    <h1>Usuários</h1>
                    <button onClick={() => setModalCadastroAberto(true)}>Adicionar Usuario</button>
                </div>
                <div className="filtrosMinhaConta">
                    <input placeholder="buscar email" onChange={filtroBusca}></input>
                    <div className="ctrlExibicaoGrid">
                        <select value={qtdeExibicao} onChange={(e) => setQtdeExibicao(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                        <div className="paginacaoMinhaConta">
                            {/* <span>{`${pagina} de ${totalPaginas}`}</span> */}
                            <button className="btnPaginacao" onClick={() => mudarPagina(pagina - 1)} disabled={pagina === 1}><FontAwesomeIcon icon={faArrowLeft} /></button>
                            <button className="btnPaginacao" onClick={() => mudarPagina(pagina + 1)} disabled={pagina === totalPaginas}><FontAwesomeIcon icon={faArrowRight} /></button>
                        </div>
                    </div>
                </div>
                <div className="gridMinhaConta">
                    <div className="cabecalhoGridMinhaConta">
                        <div className="celulaComOrdenacao" style={{ width: '30vw' }}>
                            <p>{'Email'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'email')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'email')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        <div className="celulaComOrdenacao" style={{ width: '20vw' }}>
                            <p>{'Função'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'funcao')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'funcao')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        <p style={{ width: '20vw' }} >{'Ações'}</p>
                    </div>
                    <div className="conteudoGridMinhaConta">
                        {usuarios.map((usuario) => (
                            <div className="linhasGridMinhaConta" key={usuario.id}>
                                <p style={{ width: '30vw' }}>{usuario.email}</p>
                                <p style={{ width: '20vw' }}>{usuario.funcao}</p>
                                <div className="botoesAcao">
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={() => {
                                            setModalConfirmacaoAberto(true)
                                            setUsuarioParaExcluir(usuario.id)
                                            setEmailUsuarioParaExcluir(usuario.email)
                                        }}
                                        style={{ backgroundColor: 'var(--vermelhoLixeira)' }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        onClick={() => {
                                            setModalEdicaoAberto(true)
                                            setUsuarioSelecionadoId(usuario.id)
                                        }}
                                        style={{ backgroundColor: 'var(--azulEdicao)' }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageUsuario;
