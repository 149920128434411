import React, { /*useEffect,*/ useState } from 'react';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import '../../assets/styles/registroAgendamento.css'; // Para estilização adicional

const RegistroAgendamento = ({ textoBotao, clientes, barbeiros, agendamentoId }) => {
  const [cliente, setCliente] = useState('');
  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [barbeiroSelecionado, setBarbeiroSelecionado] = useState(null);
  const [servicosBarbeiro, setServicosBarbeiro] = useState([]);
  const [diaSelecionado, setDiaSelecionado] = useState('');
  const redirecionar = useNavigate();

  // useEffect(() => {
  //   if (agendamentoId) {
  //     const fetchBarbeiroData = async () => {
  //       try {
  //         const response = await api.post(`/barbeiro/id`, {
  //           agendamentoId
  //         });
  //         const barbeiroData = response.data.barbeiro;
  //         const emailBarbeiro = response.data.emailUsuario;
  //         setNome(barbeiroData.nome || '');
  //         setSobre(barbeiroData.sobre || '');
  //         setEmail(emailBarbeiro || '');
  //       } catch (error) {
  //         console.error('Erro ao carregar dados do usuário:', error);
  //       }
  //     };
  //     fetchBarbeiroData();
  //   }
  // }, [agendamentoId]);

  const registrar = async (e) => {
    e.preventDefault();
    try {
      if (agendamentoId) {
        await api.put('/atualizarAgendamento', {
          agendamentoId,
          cliente,
          barbeiroSelecionado,
          servicosSelecionados
        })
      } else {
        await api.post('/cadastroAgendamento', {
          cliente,
          barbeiroSelecionado,
          servicosSelecionados
        });
      }
      redirecionar('/minha-conta');
    } catch (error) {
      console.error('Erro ao registrar barbeiro:', error);
    }
  };

  const selecionarBarbeiro = async (barbeiroId) => {
    setBarbeiroSelecionado(barbeiroId);
    const servicosBarbeiroData = await api.post('/servicosBarbeiro', {
      barbeiroId,
    });
    setServicosBarbeiro(servicosBarbeiroData.data);
  };

  const selecionarServico = (servicoId) => {
    setServicosSelecionados((prevSelecionados) =>
      prevSelecionados.includes(servicoId)
        ? prevSelecionados.filter((id) => id !== servicoId)
        : [...prevSelecionados, servicoId]
    );
  };
  console.log(cliente,barbeiroSelecionado,servicosSelecionados);

  return (
    <form onSubmit={registrar}>
      <div>
        <label>Selecione o cliente</label>
        <select
          value={cliente}
          onChange={(e) => setCliente(e.target.value)}>
          <option value=''>Selecione...</option>
          {clientes.map((cliente) => (
            <option key={cliente.id} value={cliente.id}>
              {cliente.nome}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Selecione o barbeiro</label>
        <div className="barbeiros-container">
          {barbeiros.map((barbeiro) => (
            <div
              key={barbeiro.id}
              className={`barbeiro-card ${barbeiroSelecionado === barbeiro.id ? 'selecionado' : ''}`}
              onClick={() => selecionarBarbeiro(barbeiro.id)}
              style={{
                opacity: barbeiroSelecionado === barbeiro.id || barbeiroSelecionado === null ? 1 : 0.5,
              }}
            >
              <div className="foto-circulo">
                <FontAwesomeIcon icon={faUser} size="3x" />
              </div>
              <p>{barbeiro.nome}</p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <label>Selecione o dia</label>
        <input
          type="date"
          value={diaSelecionado}
          onChange={(e) => setDiaSelecionado(e.target.value)}
        />
      </div>
      <p>Data selecionada: {diaSelecionado}</p>
      <div>
        <label>Selecione os serviços</label>
        <div className="servico-container">
          {servicosBarbeiro.map((servico) => (
            <div
              key={servico.id}
              className={`servico-card ${servicosSelecionados.includes(servico.id) ? 'selecionado' : ''}`}
              onClick={() => selecionarServico(servico.id)}
              style={{
                opacity: servicosSelecionados.includes(servico.id) || servicosSelecionados.length === 0 ? 1 : 0.5,
              }}
            >
              <p>{servico.nome}</p>
            </div>
          ))}
        </div>
      </div>
      <button type="submit">{textoBotao}</button>
    </form>
  );
};

export default RegistroAgendamento;
