import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import CriarConta from './pages/CriarContaPage';
import MinhaConta from './pages/PageMinhaConta';
import Usuarios from './pages/PageUsuarios';
import Barbeiros from './pages/PageBarbeiros';
import Agendamentos from './pages/PageAgendamento';
import Clientes from './pages/PageCliente';
import Servicos from './pages/PageServicos';
import './assets/styles/gerais.css';
import { isLoggedIn, isAdmin, isBarber } from './utils/auth'

const RotaPrivada = ({ children }) => {
  return isLoggedIn() ? children : <Navigate to="/login" />;
};
const RotaAdmin = ({ children }) => {
  return (isAdmin() && isLoggedIn()) ? children : <Navigate to="/login" />
}
const RotaBarbeiroAdmin = ({ children }) => {
  return ((isBarber() || isAdmin()) && isLoggedIn()) ? children : <Navigate to="/login" />
}
const PublicRoute = ({ children }) => {
  return isLoggedIn() ? <Navigate to="/minha-conta" /> : children;
};

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <Home />
            } />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            }
          />
          <Route
            path="/criar-conta"
            element={
              <PublicRoute>
                <CriarConta />
              </PublicRoute>
            }
          />
          <Route
            path="/minha-conta"
            element={
              <RotaPrivada>
                <MinhaConta />
              </RotaPrivada>
            } />
          <Route
            path="gerenciar/usuarios"
            element={
              <RotaAdmin>
                <Usuarios />
              </RotaAdmin>
            } />
          <Route
            path="gerenciar/servicos"
            element={
              <RotaAdmin>
                <Servicos />
              </RotaAdmin>
            } />
          <Route
            path="gerenciar/barbeiros"
            element={
              <RotaAdmin>
                <Barbeiros />
              </RotaAdmin>
            } />
          <Route
            path="gerenciar/clientes"
            element={
              <RotaAdmin>
                <Clientes />
              </RotaAdmin>
            } />
          <Route
            path="gerenciar/horarios"
            element={
              <RotaBarbeiroAdmin>
                <Barbeiros />
              </RotaBarbeiroAdmin>
            } />
          <Route path='gerenciar/agendamentos' element={
            <RotaBarbeiroAdmin>
              <Agendamentos />
            </RotaBarbeiroAdmin>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
