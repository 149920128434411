import CryptoJS from 'crypto-js';

const SECRET_KEY = 'projetinhoNeahPae';

export const encriptar = (data) =>{
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
}

export const desencriptar = (ciphertext)=> {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    const dadosDesencriptados = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(dadosDesencriptados);
}
