import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/criarConta.css';

const CadastroClienteFrom = ({ textoBotao, clienteId }) => {
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState('');
  const [bairro, setBairro] = useState('');
  const [errorCep, setErrorCep] = useState('');
  const redirecionar = useNavigate();

  useEffect(() => {
    if (clienteId) {
      const fetchUserData = async () => {
        try {
          const response = await api.post(`/cliente/id`, {
            clienteId
          });
          const clienteData = response.data.cliente;
          const emailCliente = response.data.emailCliente;
          setEmail(emailCliente || '');
          setNome(clienteData.nome || '');
          setTelefone(clienteData.telefone || '');
          setCep(clienteData.cep || '');
          setCidade(clienteData.cidade || '');
          setBairro(clienteData.bairro || '');
        } catch (error) {
          console.error('Erro ao carregar dados do cliente:', error);
        }
      };
      fetchUserData();
    }
  }, [clienteId]);

  const registrar = async (e) => {
    e.preventDefault();
    try {
      if (clienteId) {
        await api.put('/atualizarCliente', {
          clienteId,
          nome: nome,
          username: email,
          email: email,
          telefone: telefone,
          cep: cep,
          cidade: cidade,
          bairro: bairro
        });
      } else {
        await api.post('/cadastroCliente', {
          nome: nome,
          email: email,
          telefone: telefone,
          cep: cep,
          cidade: cidade,
          bairro: bairro
        });
      }
      redirecionar('/login');
    } catch (error) {
      console.error('Erro ao registrar o cliente:', error);
    }
  };
  const checkCep = () => {
    const cepFormatado = cep.replace(/\D/g, '');
    if (cepFormatado.length === 8) {
      fetch(`https://viacep.com.br/ws/${cepFormatado}/json/`)
        .then(res => res.json())
        .then(data => {
          if (!data.erro) {
            setErrorCep('');
            setCidade(data.localidade);
            setBairro(data.bairro);
          } else {
            setErrorCep('CEP não encontrado.');
            setCidade('');
            setBairro('');
          }
        })
        .catch(() => {
          setErrorCep('Erro ao buscar o CEP.');
          setCidade('');
          setBairro('');
        });
    } else {
      setErrorCep('CEP inválido.');
    }
  };


  return (
    <form onSubmit={registrar}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div>
          <label>Nome</label>
          <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
        </div>
        <div>
          <label>Email</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div>
          <label>Telefone</label>
          <input type="text" value={telefone} onChange={(e) => setTelefone(e.target.value)} />
        </div>
        <div>
          <label>Cep</label>
          <input type="text" value={cep} onChange={(e) => setCep(e.target.value)} onBlur={checkCep} />
          {errorCep && <p style={{ color: 'red' }}>{errorCep}</p>}
        </div>
        <div>
          <label>Cidade</label>
          <input type="text" value={cidade} onChange={(e) => setCidade(e.target.value)} readOnly />
        </div>
        <div>
          <label>Bairro</label>
          <input type="text" value={bairro} onChange={(e) => setBairro(e.target.value)} readOnly />
        </div>
        <div style={{ display: 'flex', gap: '20px' }}>
          <button type="submit">{textoBotao}</button>
        </div>
      </div>
    </form>
  );
};

export default CadastroClienteFrom;
