import { useEffect, useState } from "react";
import SideMenu from "../components/SideMenu";
import ModalCadastroServico from "../components/modais/ModalCadastroServico";
import ModalConfirmacaoAcao from '../components/modais/ModalConfirmacaoAcao';
import ModalSucesso from '../components/modais/ModalSucesso';
import '../assets/styles/padraoMinhaConta.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDesc, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { buscarServico, desativarServico, getServicos, ordenarServicos } from "../services/controller/servicoController";
import { useNavigate } from "react-router-dom";

const PageServicos = () => {
    const [servicos, setServicos] = useState([]);
    const [error, setError] = useState(null);
    const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
    const [modalEdicaoAberto, setModalEdicaoAberto] = useState(false);
    const [servicoSelecionadoId, setServicoSelecionadoId] = useState(null);
    const [modalConfirmacaoAberto, setModalConfirmacaoAberto] = useState(false);
    const [servicoParaExcluir, setServicoParaExcluir] = useState(null);
    const [nomeServicoParaExcluir, setNomeServicoParaExcluir] = useState('');
    const [modalSucessoExclusaoAberto, setModalSucessoExclusaoAberto] = useState(false);
    const redirecionar = useNavigate();

    useEffect(() => {
        const fetchServicos = async () => {
            try {
                const servicosData = await getServicos();
                setServicos(servicosData);
            } catch (error) {
                setError(error.message);
            }
        };
        fetchServicos();
    }, []);

    const filtroBusca = async (e) => {
        const busca = e.target.value;
        const servicosRetorno = await buscarServico(busca);
        setServicos(servicosRetorno);
    };

    const ordenacao = (ordem, criterio) => {
        const servicosOrdenados = ordenarServicos(servicos, criterio, ordem);
        setServicos(servicosOrdenados);
    };

    const fecharModalCadastro = () => {
        setModalCadastroAberto(false);
        setServicoSelecionadoId(null);
    };
    const fecharModalEdicao = () => {
        setModalEdicaoAberto(false);
        setServicoSelecionadoId(null);
    };
    const fecharModalConfirmacao = () => {
        setModalConfirmacaoAberto(false);
    };
    const fecharModalSucesso = () => {
        setModalSucessoExclusaoAberto(false);
        redirecionar('/gerenciar/servicos');
    }

    const confirmarExclusao = async () => {
        if (servicoParaExcluir) {
            try {
                await desativarServico(servicoParaExcluir, setServicos, setError);
                fecharModalConfirmacao();
                setModalSucessoExclusaoAberto(true);
            } catch (error) {
                setError(error.message);
                fecharModalConfirmacao();
            }
        }
    };

    if (error) {
        return error;
    }

    return (
        <>
            <SideMenu />
            <div className="divPrincipalMinhaConta">
                <ModalSucesso
                    isOpen={modalSucessoExclusaoAberto}
                    titulo={`Sucesso!`}
                    mensagem={`Serviço ${nomeServicoParaExcluir} excluido com sucesso`}
                    acaoConfirmar={fecharModalSucesso}
                />
                <ModalConfirmacaoAcao
                    isOpen={modalConfirmacaoAberto}
                    titulo={`Excluir ${nomeServicoParaExcluir}?`}
                    mensagem="Você tem certeza que deseja excluir este serviço?"
                    acaoCancelar={fecharModalConfirmacao}
                    acaoConfirmar={confirmarExclusao}
                />
                <ModalCadastroServico
                    isOpen={modalCadastroAberto}
                    titulo="Novo Serviço"
                    textoBotao={'Adicionar Serviço'}
                    onCancel={fecharModalCadastro}
                />
                <ModalCadastroServico
                    isOpen={modalEdicaoAberto}
                    titulo="Editar Serviço"
                    textoBotao={'Salvar Edição'}
                    servicoId={servicoSelecionadoId}
                    onCancel={fecharModalEdicao}
                />
                <div className="superiorMinhaConta">
                    <h1>Serviços</h1>
                    <button onClick={() => setModalCadastroAberto(true)}>Adicionar Serviços</button>
                </div>
                <div className="filtrosMinhaConta">
                    <input placeholder="buscar serviço" onChange={filtroBusca}></input>
                </div>
                <div className="gridMinhaConta">
                    <div className="cabecalhoGridMinhaConta">
                        <div className="celulaComOrdenacao" style={{ width: '30vw' }}>
                            <p>{'Nome'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'nome')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'nome')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        <div className="celulaComOrdenacao" style={{ width: '30vw' }}>
                            <p>{'Valor'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'preco')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'preco')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        <div className="celulaComOrdenacao" style={{ width: '20vw' }}>
                            <p>{'Numero de janelas'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'numJanelas')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'numJanelas')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        <p style={{ width: '20vw' }} >{'Ações'}</p>
                    </div>
                    <div className="conteudoGridMinhaConta">
                        {servicos.map((servico) => (
                            <div className="linhasGridMinhaConta" key={servico.id}>
                                <p style={{ width: '30vw' }}>{servico.nome}</p>
                                <p style={{ width: '30vw' }}>{servico.preco}</p>
                                <p style={{ width: '20vw' }}>{servico.numJanelas}</p>
                                <div className="botoesAcao">
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ backgroundColor: 'var(--vermelhoLixeira)' }}
                                        onClick={() => {
                                            setModalConfirmacaoAberto(true)
                                            setServicoParaExcluir(servico.id)
                                            setNomeServicoParaExcluir(servico.nome)
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        onClick={() => {
                                            setModalEdicaoAberto(true)
                                            setServicoSelecionadoId(servico.id)
                                        }}
                                        style={{ backgroundColor: 'var(--azulEdicao)' }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageServicos;
