import React, { useState, useEffect } from "react";
import SideMenu from "../components/SideMenu";
import '../assets/styles/padraoMinhaConta.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSortDesc, faSortUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import ModalCadastroBarbeiro from "../components/modais/ModalCadastroBarbeiro";
import ModalConfirmacaoAcao from '../components/modais/ModalConfirmacaoAcao';
import ModalSucesso from '../components/modais/ModalSucesso';
import { ordenarBarbeiros, desativarBarbeiro, getBarbeirosAtivos, buscarBarbeiro } from "../services/controller/barbeiroController";
import { useNavigate } from "react-router-dom";


const PageBarbeiro = () => {
    const [barbeiros, setBarbeiros] = useState([]);
    const [error, setError] = useState(null);
    const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
    const [modalEdicaoAberto, setModalEdicaoAberto] = useState(false);
    const [barbeiroSelecionadoId, setBarbeiroSelecionadoId] = useState(null);
    const [modalConfirmacaoAberto, setModalConfirmacaoAberto] = useState(false);
    const [barbeiroParaExcluir, setBarbeiroParaExcluir] = useState(null);
    const [nomeBarbeiroParaExcluir, setNomeBarbeiroParaExcluir] = useState('');
    const [modalSucessoExclusaoAberto, setModalSucessoExclusaoAberto] = useState(false);
    const redirecionar = useNavigate();

    useEffect(() => {
        const fetchBarbeiros = async () => {
            try {
                const barbeiroData = await getBarbeirosAtivos();
                setBarbeiros(barbeiroData);
            } catch (error) {
                setError(error.message);
            }
        };
        fetchBarbeiros();
    }, []);

    const filtroBusca = async (e) => {
        const busca = e.target.value;
        const barbeirosRetorno = await buscarBarbeiro(busca);
        setBarbeiros(barbeirosRetorno);
    };

    const ordenacao = (ordem, criterio) => {
        const barbeirosOrdenados = ordenarBarbeiros(barbeiros, criterio, ordem);
        setBarbeiros(barbeirosOrdenados);
    };

    const fecharModalCadastro = () => {
        setModalCadastroAberto(false);
        setBarbeiroSelecionadoId(null);
    };
    const fecharModalEdicao = () => {
        setModalEdicaoAberto(false);
        setBarbeiroSelecionadoId(null);
    };

    const fecharModalConfirmacao = () => {
        setModalConfirmacaoAberto(false);
    };

    const fecharModalSucesso = () => {
        setModalSucessoExclusaoAberto(false);
        redirecionar('/gerenciar/barbeiros');
    }

    const confirmarExclusao = async () => {
        if (barbeiroParaExcluir) {
            try {
                await desativarBarbeiro(barbeiroParaExcluir, setBarbeiros, setError);
                fecharModalConfirmacao();
                setModalSucessoExclusaoAberto(true);
            } catch (error) {
                setError(error.message);
                fecharModalConfirmacao();
            }
        }
    };

    if (error) {
        return <p>Erro: {error}</p>;
    }

    return (
        <>
            <SideMenu />
            <div className="divPrincipalMinhaConta">
                <ModalSucesso
                    isOpen={modalSucessoExclusaoAberto}
                    titulo={`Sucesso!`}
                    mensagem={`Barbeiro ${nomeBarbeiroParaExcluir} excluido com sucesso`}
                    acaoConfirmar={fecharModalSucesso}
                />
                <ModalConfirmacaoAcao
                    isOpen={modalConfirmacaoAberto}
                    titulo={`Excluir ${nomeBarbeiroParaExcluir}?`}
                    mensagem="Você tem certeza que deseja excluir este barbeiro?"
                    acaoCancelar={fecharModalConfirmacao}
                    acaoConfirmar={confirmarExclusao}
                />
                <ModalCadastroBarbeiro
                    isOpen={modalCadastroAberto}
                    titulo={'Novo Barbeiro'}
                    textoBotao={'Adicionar Barbeiro'}
                    onCancel={fecharModalCadastro}
                />
                <ModalCadastroBarbeiro
                    isOpen={modalEdicaoAberto}
                    titulo={'Editar Barbeiro'}
                    textoBotao={'Salvar edição'}
                    barbeiroId={barbeiroSelecionadoId}
                    onCancel={fecharModalEdicao}
                />
                <div className="superiorMinhaConta">
                    <h1>Barbeiros</h1>
                    <button onClick={() => setModalCadastroAberto(true)}>Adicionar Barbeiro</button>
                </div>
                <div className="filtrosMinhaConta">
                    <input placeholder="buscar barbeiro" onChange={filtroBusca}></input>
                </div>
                <div className="gridMinhaConta">
                    <div className="cabecalhoGridMinhaConta">
                        <div className="celulaComOrdenacao" style={{ width: '30vw' }}>
                            <p>{'Nome'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'nome')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'nome')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        <p style={{ width: '30vw' }}>{'Usuario Id'}</p>
                        <p style={{ width: '20vw' }}>{'Ações'}</p>
                    </div>
                    <div className="conteudoGridMinhaConta">
                        {barbeiros.map((barbeiro) => (
                            <div className="linhasGridMinhaConta" key={barbeiro.id}>
                                <p style={{ width: '30vw' }}>{barbeiro.nome}</p>
                                <p style={{ width: '30vw' }}>{barbeiro.usuarioId}</p>
                                <div className="botoesAcao">
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ backgroundColor: 'var(--vermelhoLixeira)' }}
                                        onClick={() => {
                                            setModalConfirmacaoAberto(true)
                                            setBarbeiroParaExcluir(barbeiro.id)
                                            setNomeBarbeiroParaExcluir(barbeiro.nome)
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        onClick={() => {
                                            setModalEdicaoAberto(true)
                                            setBarbeiroSelecionadoId(barbeiro.id)
                                        }}
                                        style={{ backgroundColor: 'var(--azulEdicao)' }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageBarbeiro;
