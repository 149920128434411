import React, { useState } from 'react';
import '../assets/styles/calendario.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Calendar = ({onDiaSelecionadoChange}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [diaSelecionado, setDiaSelecionado] = useState(new Date());


  // const formatarDataParaPostgres = (data) => {
  //   return data.toISOString().split('T')[0];
  // };
  // const diaSelecionadoEFormatado = formatarDataParaPostgres(diaSelecionado);
  
  const diasDaSemana = () => {
    const dias = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
    return dias.map((dia, index) => <div key={index}>{dia}</div>);
  };

  const selecaoDia = (dia) => {
    const dataSelecionada = new Date(currentDate.getFullYear(), currentDate.getMonth(), dia);
    setDiaSelecionado(dataSelecionada);
    onDiaSelecionadoChange(dataSelecionada);
  };

  const diasDoMes = () => {
    const inicioMes = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const fimMes = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const inicioSemana = inicioMes.getDay(); // 0 (Domingo) a 6 (Sábado)
    const diasDoMes = fimMes.getDate();

    const fimMesAnterior = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    const diasMesAnterior = fimMesAnterior.getDate();

    const calendario = [];

    for (let j = inicioSemana - 1; j >= 0; j--) {
      const diaAnterior = diasMesAnterior - j;
      calendario.push(
        <div key={`anterior-${diaAnterior}`} className="dia mes-anterior">
          {diaAnterior}
        </div>
      );
    }

    for (let dia = 1; dia <= diasDoMes; dia++) {
      const semanaAtual = Math.floor((dia + inicioSemana - 1) / 7);
      const semanaSelecionada = diaSelecionado
        && diaSelecionado.getFullYear() === currentDate.getFullYear()
        && diaSelecionado.getMonth() === currentDate.getMonth()
        && Math.floor((diaSelecionado.getDate() + inicioSemana - 1) / 7) === semanaAtual;

      const selecionado = semanaSelecionada && dia === diaSelecionado.getDate();
      const classeDia = selecionado ? 'dia selected' : 'dia';
      const classeSemana = semanaSelecionada ? 'selected-week' : '';

      calendario.push(
        <div key={dia} className={`${classeDia} ${classeSemana}`} onClick={() => selecaoDia(dia)}>
          {dia}
        </div>
      );
    }

    const totalDias = inicioSemana + diasDoMes; 
    const diasRestantes = 7 - (totalDias % 7); 

    if (diasRestantes < 7) {
      for (let dia = 1; dia <= diasRestantes; dia++) {
        calendario.push(
          <div key={`proximo-${dia}`} className="dia mes-proximo">
            {dia}
          </div>
        );
      }
    }

    return calendario;
  };

  const mudarMes = (incremento) => {
    const novaData = new Date(currentDate.getFullYear(), currentDate.getMonth() + incremento, 1);
    setCurrentDate(novaData);
  };

  return (
    <div className="calendar">
      <div className="header">
        <button onClick={() => mudarMes(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <h4>{currentDate.toLocaleDateString('pt-BR', { month: 'long', year: 'numeric' }).replace(' de ', ' ')}</h4>
        <button onClick={() => mudarMes(1)}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
      <div className="nomesDias">
        {diasDaSemana()}
      </div>
      <div className="days">
        {diasDoMes()}
      </div>
    </div>
  );
};

export default Calendar;
