import React, { useState } from 'react';
import '../assets/styles/sideMenu.css';
import { isAdmin, /*nomeUsuario*/ } from '../utils/auth';
import ModalConfirmacaoAcao from './modais/ModalConfirmacaoAcao';
import { useNavigate, NavLink } from 'react-router-dom';
import { IconAgenda, IconBarbeiros, IconClientes, IconServicos, IconUsuarios } from '../assets/Icons';
import logo from '../assets/images/LogoCortae.png';
import logoCasaBrasil from '../assets/images/CasaBrasilLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';


const SideMenu = () => {
  const [modalConfirmacaoLogoutAberto, setModalConfirmacaoLogoutAberto] = useState(false);
  const redirecionar = useNavigate();

  const cancelarLogout = () => {
    setModalConfirmacaoLogoutAberto(false);
  };
  const confirmacaoLogout = () => {
    localStorage.removeItem('usuarioAtual');
    localStorage.removeItem('token');
    setModalConfirmacaoLogoutAberto(false);
    redirecionar('/login');
  };

  const menuLinks = [
    {
      path: '/gerenciar/agendamentos',
      label: 'Agenda',
      icon: IconAgenda,
    },
    {
      path: '/gerenciar/barbeiros',
      label: 'Barbeiros',
      icon: IconBarbeiros,
    },
    {
      path: '/gerenciar/clientes',
      label: 'Clientes',
      icon: IconClientes,
    },
    {
      path: '/gerenciar/servicos',
      label: 'Serviços',
      icon: IconServicos,
    },
    {
      path: '/gerenciar/usuarios',
      label: 'Usuários',
      icon: IconUsuarios,
    },
  ];

  return (
    <div className="divPrincipalSideMenu">
      <ModalConfirmacaoAcao
        isOpen={modalConfirmacaoLogoutAberto}
        titulo="Sair?"
        mensagem="Você tem certeza que deseja sair?"
        acaoCancelar={cancelarLogout}
        acaoConfirmar={confirmacaoLogout}
      />
      <div className='topSideMenu'>
        {/* <p>Olá, {nomeUsuario() ? nomeUsuario() : 'visitante'}. Seja bem vindo a</p> */}
        <img src={logoCasaBrasil} alt="Logo" />
      </div>
      <div className='middleSideMenu'>
        {isAdmin() && (
          <nav className='itensMenu'>
            {menuLinks.map((link) => (
              <NavLink
                key={link.path}
                to={link.path}
                style={({ isActive }) => ({
                  stroke: isActive ? 'var(--dourado)' : 'white',
                  color: isActive ? 'var(--dourado)' : 'white',
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center'
                })}
              >
                <span style={{ marginRight: '8px' }}>{link.icon}</span>
                <p>{link.label}</p>
              </NavLink>
            ))}
          </nav>
        )}

        <div onClick={() => setModalConfirmacaoLogoutAberto(true)} className='divSair'>
          <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ marginRight: '8px', height: '25px' }} />
          <p>Sair</p>
        </div>

      </div>
      <div className='bottomSideMenu'>
        <i>feito por</i>
        <img src={logo} alt="Logo" />
      </div>
    </div>
  );
};

export default SideMenu;
