import SideMenu from "../components/SideMenu";
import '../assets/styles/minhaConta.css'
import { isAdmin, isBarber, isClient } from "../utils/auth";

const MinhaConta = () => {

    return (
        <>
            <SideMenu />
            <div className="divPrincipalMinhaConta">
                {
                    isAdmin() && (
                        <>
                            <p color="#000">Essa é a página de minha conta para o Administrador</p>
                        </>
                    )
                }
                {
                    isBarber() && (
                        <>
                            <p color="#000">Essa é a página de minha conta para o Barbeiro</p>
                        </>
                    )
                }
                {
                    isClient() && (
                        <>
                            <p color="#000">Essa é a página de minha conta para o Cliente</p>
                        </>
                    )
                }                
            </div>
        </>
    );
};

export default MinhaConta;
