export const IconUsuarios = (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <circle cx="12.5" cy="12.5" r="12" />
    <circle cx="12.5" cy="9.5" r="4" />
    <path d="M20.7636 21.5C19.8642 17.7706 16.5057 15 12.5002 15C8.49465 15 5.13622 17.7706 4.23682 21.5" />
  </svg>
);

export const IconServicos = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
    <path d="M4.84619 6L2.84619 1.5L10.725 11.5M16.8462 19L12.4657 13.7093M5.34619 7L9.07952 14M15.3462 19.5L10.977 14.6439M10.725 11.5L18.8462 1L12.4657 13.7093M10.725 11.5L12.4657 13.7093M5.34619 19L9.07952 14M9.07952 14L9.34619 14.5H10.8462L10.977 14.6439M6.34619 20L10.977 14.6439" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.28585 19.1586C4.89615 18.9735 4.4602 18.8699 4.00006 18.8699C2.34321 18.8699 1.00006 20.213 1.00006 21.8699C1.00006 23.5267 2.34321 24.8699 4.00006 24.8699C5.65692 24.8699 7.00006 23.5267 7.00006 21.8699C7.00006 21.1628 6.75543 20.5128 6.34619 20" strokeLinecap="round" />
    <path d="M15.6422 19.8334C15.3492 20.1501 15.1203 20.5353 14.983 20.9745C14.4888 22.5559 15.3701 24.2386 16.9515 24.7328C18.533 25.2271 20.2156 24.3458 20.7099 22.7643C21.2041 21.1829 20.3228 19.5003 18.7414 19.006C18.0665 18.7951 17.3731 18.8347 16.7616 19.0723" strokeLinecap="round" />
    <path d="M5.34619 21.75C5.34619 22.4404 4.78655 23 4.09619 23C3.40584 23 2.84619 22.4404 2.84619 21.75C2.84619 21.0596 3.40584 20.5 4.09619 20.5C4.78655 20.5 5.34619 21.0596 5.34619 21.75Z" />
    <path d="M18.1336 20.5493C18.7925 20.7552 19.1597 21.4563 18.9538 22.1152C18.7479 22.7742 18.0468 23.1414 17.3878 22.9354C16.7289 22.7295 16.3617 22.0284 16.5676 21.3695C16.7736 20.7105 17.4747 20.3433 18.1336 20.5493Z" />
    <path d="M20.8462 23C21.1795 23.0833 21.6794 23.2083 22.0961 23.3125C22.5787 23.4331 23.0512 23.719 23.1506 24.2065C23.2299 24.5954 23.1252 25 22.3462 25C21.1462 25 20.1795 24.6667 19.8462 24.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const IconClientes = (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
    <path d="M9.66565 22.7305L11.9448 22.4773C12.241 22.4443 12.5 22.6762 12.5 22.9742V23.8569C12.5 24.1331 12.2761 24.3569 12 24.3569H1C0.723858 24.3569 0.5 24.1331 0.5 23.8569V20.3569C0.5 20.0808 0.723858 19.8569 1 19.8569H2H3.5H5H6.5H7.5C7.77614 19.8569 8 20.0808 8 20.3569V21.2397C8 22.1337 8.7771 22.8292 9.66565 22.7305Z" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21.5 19.3569V13.3569M20.5 22.8569L21.5 19.8569L22.5 22.8569" strokeLinecap="round" />
    <path d="M19.5 15.3569C19.5 14.2524 20.3954 13.3569 21.5 13.3569C22.6046 13.3569 23.5 14.2524 23.5 15.3569V19.3569C23.5 19.6331 23.2761 19.8569 23 19.8569H20C19.7239 19.8569 19.5 19.6331 19.5 19.3569V15.3569Z" />
    <path d="M23.5 19.3569C23.5 18.2524 22.6046 17.8569 21.5 17.8569C20.3954 17.8569 19.5 18.2524 19.5 19.3569" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="23.5" cy="22.8569" r="1" />
    <circle cx="19.5" cy="22.8569" r="1" />
    <path d="M19 23.8569L18 24.8569" />
    <path d="M17 5.35712V7.73212V10.1071V14.8571C16.6667 16.5238 15.2 20.0572 12 20.8572C8.8 20.0572 7.33333 16.5239 7 14.8572V10.1072V8.35718M10.5 2.85719C7.5 2.85719 7.16667 4.3572 7 5.35724V7.73224V8.35718M10 4.35732C11 2.35732 14.0996 -0.842148 18.5 2.35732C19.5 3.0844 20.5 4.85693 18 6.85718C15.5606 8.80894 13 7.69052 12 6.85718C9.2 5.25718 7.5 7.19052 7 8.35718M6 14.3572C7 14.1906 8 13.8572 9 13.3572C10 12.8572 11 11.8572 12 13.3572C13 11.8572 14 12.8572 15 13.3572C16 13.8572 16.5 13.8572 18 14.3572C16.5 14.8572 13 15.3572 12 14.8572C11 15.3572 8 15.1572 6 14.3572Z" strokeLinecap="square" />
    <path d="M5 9.85693C5 9.30465 5.44772 8.85693 6 8.85693H7V11.8569H6C5.44772 11.8569 5 11.4092 5 10.8569V9.85693Z" />
    <path d="M19.5 9.85693C19.5 9.30465 19.0523 8.85693 18.5 8.85693H17.5V11.8569H18.5C19.0523 11.8569 19.5 11.4092 19.5 10.8569V9.85693Z" />
    <path d="M9.5 10.3569H10M14.5 10.3569H15" strokeLinecap="square" />
  </svg>
);

export const IconBarbeiros = (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
    <path d="M1.49273 1.41505C1.44581 1.14292 1.18717 0.960351 0.915047 1.00727C0.642919 1.05419 0.460351 1.31283 0.50727 1.58495L1.49273 1.41505ZM18.5 16.5C18.7761 16.5 19 16.2761 19 16C19 15.7239 18.7761 15.5 18.5 15.5V16.5ZM11 18V17.5H10.5V18H11ZM11 20.5H10.5V21H11V20.5ZM13 20.5V21H13.5V20.5H13ZM13 18H13.5V17.5H13V18ZM17.5 7.5L17.9743 7.65811C18.0252 7.50564 17.9996 7.33803 17.9056 7.20764C17.8116 7.07726 17.6607 7 17.5 7V7.5ZM16.7536 9.73929L16.2792 9.58117L16.1346 10.015L16.5539 10.1977L16.7536 9.73929ZM17.5097 12.9019C17.4556 13.1727 17.6312 13.4361 17.9019 13.4903C18.1727 13.5444 18.4361 13.3688 18.4903 13.0981L17.5097 12.9019ZM0.50727 1.58495L2.72103 14.4248L3.70649 14.2549L1.49273 1.41505L0.50727 1.58495ZM5.18468 16.5H18.5V15.5H5.18468V16.5ZM10.5 18V20.5H11.5V18H10.5ZM11 21H13V20H11V21ZM13.5 20.5V18H12.5V20.5H13.5ZM13 17.5H11V18.5H13V17.5ZM9 11H15.6981V10H9V11ZM17.5 7H9V8H17.5V7ZM17.2279 9.8974L17.9743 7.65811L17.0257 7.34189L16.2792 9.58117L17.2279 9.8974ZM16.5539 10.1977C16.9321 10.3624 17.8611 11.1451 17.5097 12.9019L18.4903 13.0981C18.9389 10.8549 17.7393 9.62328 16.9532 9.28088L16.5539 10.1977ZM15.6981 11C16.3922 11 17.0084 10.5559 17.2279 9.8974L16.2792 9.58117C16.1959 9.83129 15.9618 10 15.6981 10V11ZM7 9C7 10.1046 7.89543 11 9 11V10C8.44772 10 8 9.55228 8 9H7ZM8 9C8 8.44772 8.44772 8 9 8V7C7.89543 7 7 7.89543 7 9H8ZM2.72103 14.4248C2.92778 15.6239 3.96786 16.5 5.18468 16.5V15.5C4.45459 15.5 3.83054 14.9743 3.70649 14.2549L2.72103 14.4248Z" fill="white" />
    <path d="M1 1.5L2.5 1.25C3.32843 1.11193 4.11193 1.67157 4.25 2.5V2.5C4.38807 3.32843 3.82843 4.11193 3 4.25L1.5 4.5"/>
    <path d="M6.5 16V17C6.5 17.5523 6.94772 18 7.5 18H16.5C17.0523 18 17.5 17.5523 17.5 17V16M5.5 16V14C5.5 13.4477 5.94772 13 6.5 13H17.5C18.0523 13 18.5 13.4477 18.5 14V16"strokeLinecap="round" />
    <path d="M17 17.5L18.5 21C18.7743 21.4115 19.5452 22.0938 20.7013 22.0996M20.7013 22.0996C20.95 22.1009 21.2167 22.0708 21.5 22C22.5226 21.7444 23.3273 21.4751 23.862 21.2705C24.1807 21.1486 24.2479 20.7514 23.9677 20.5567C22.8577 19.7852 21.1769 19.4542 20.7013 22.0996Z"/>
    <path d="M4.5 25.5C5.0521 24.3958 6.67104 23.9012 8.01054 23.6797C9.10031 23.4995 10 22.6046 10 21.5V21.3C10 20.8582 10.3582 20.5 10.8 20.5H13.2C13.6418 20.5 14 20.8582 14 21.3V21.5C14 22.6046 14.9028 23.4968 15.9846 23.7199C17.1441 23.9591 18.4791 24.4582 19 25.5"strokeLinecap="round" />
    <path d="M9.5 25.5H4.6618C4.58747 25.5 4.54059 25.4227 4.58267 25.3614C5.06104 24.6648 6.7613 23.5 10.5 23.5C14.5 23.5 11 23.5 13 23.5C16.7387 23.5 18.439 24.6648 18.9173 25.3614C18.9594 25.4227 18.9125 25.5 18.8382 25.5H14"strokeLinecap="round" />
    <path d="M1.5 4.5L1.63474 4.47754C3.23191 4.21135 4.75212 5.26062 5.06968 6.84838L5.59491 9.47456C5.81649 10.5825 5.07702 11.6538 3.96255 11.8396L3 12"/>
  </svg> 
);

export const IconAgenda = (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
    <path d="M12.5 20H6C3.23858 20 1 17.7614 1 15V6C1 3.23858 3.23858 1 6 1H17C19.7614 1 22 3.23858 22 6V13" />
    <path d="M1.5 7H21.5" />
    <circle cx="19" cy="19" r="6.5" />
    <path d="M5 10.5H7.5M10 10.5H13M15.5 10.5H18.5M5 14.5H7.5M10 14.5H13M19 17V18.5858C19 18.851 19.1054 19.1054 19.2929 19.2929L20.5 20.5" strokeLinecap="round" />
  </svg>
);