import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';

const FormRegistrarServico = ({ textoBotao, servicoId }) => {
  const [nome, setNome] = useState('');
  const [preco, setPreco] = useState('');
  const [numJanelas, setNumJanelas] = useState('');
  const [descricao, setDescricao] = useState('');
  const redirecionar = useNavigate();

  useEffect(() => {
    if (servicoId) {
      const fetchUserData = async () => {
        try {
          const response = await api.post(`/servico/id`, {
            servicoId
          });
          console.log(response);
          const servicoData = response.data.servicoRetorno;
          setNome(servicoData.nome || '');
          setPreco(servicoData.preco || '');
          setNumJanelas(servicoData.numJanelas || '');
          setDescricao(servicoData.descricao || '');
        } catch (error) {
          console.error('Erro ao carregar dados do servico:', error);
        }
      };
      fetchUserData();
    }
  }, [servicoId]);

  const registrar = async (e) => {
    e.preventDefault();
    try {
      if (servicoId) {
        await api.put('/atualizarServico', {
          servicoId,
          nome: nome,
          preco: preco,
          numJanelas: numJanelas,
          descricao: descricao
        })
      } else {
        await api.post('/cadastroServico', {
          nome: nome,
          preco: preco,
          numJanelas: numJanelas,
          descricao: descricao
        })
      }
      redirecionar('/minha-conta');
    } catch (error) {
      console.error('Erro ao registrar servico:', error);
    }
  };

  return (
    <form onSubmit={registrar}>
      <div>
        <label>Nome</label>
        <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
      </div>
      <div>
        <label>Valor</label>
        <input type="text" value={preco} onChange={(e) => setPreco(e.target.value)} />
      </div>
      <div>
        <label>Numero de Janelas</label>
        <input type="text" value={numJanelas} onChange={(e) => setNumJanelas(e.target.value)} />
      </div>
      <div>
        <label>Descrição</label>
        <input type="text" value={descricao} onChange={(e) => setDescricao(e.target.value)} />
      </div>
      <button type="submit">{textoBotao}</button>
    </form>
  )
}

export default FormRegistrarServico;